/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@include mat.core();

$drx-primary-color: #0097ac;
$drx-primary-color-light: #80cbd6;

$md-drx: (
  50: #e0f3f5,
  100: #b3e0e6,
  200: #80cbd6,
  300: #4db6c5,
  400: #26a7b8,
  500: #0097ac,
  600: #008fa5,
  700: #00849b,
  800: #007a92,
  900: #006982,
  A100: #afecff,
  A200: #7ce1ff,
  A400: #49d5ff,
  A700: #30cfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-app-primary: mat.define-palette($md-drx);
$my-app-accent: mat.define-palette($md-drx, 900);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);
@include mat.all-component-themes($my-app-theme);

.primary-color {
  color: $drx-primary-color !important;
}
.warning-color {
  color: mat-color($my-app-warn) !important;
}

.s3FolderLink {
  color: $drx-primary-color !important;
  cursor: pointer;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-radio-group {
  display: inline-flex;
  flex-direction: column;
}

.mat-radio-button {
  margin: 2px;
}

.mat-button-disabled {
  background-color: $drx-primary-color-light !important;
}

.green-snackbar {
  background: rgb(0, 159, 85);
  color: white;
}
.green-snackbar button {
  background-color: rgb(0, 159, 85);
  color: white;
  border: none;
}
.red-snackbar {
  background: #f44336;
  color: white;
}
.red-snackbar button {
  background-color: #f44336;
  color: white !important;
  border: none;
}
